<template>
    <div class="row">
        <div class="col-md-9">
            <div class="card">
                <div class="card-header bg-gray">
                    <h3 class="card-title"><i class="fas fa-list mr-2"></i>Calendario</h3>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-5"></div>
                        <div class="col-md-4">
                            <h2>
                                <i class="fa fa-arrow-left" @click="previous()" aria-hidden="true"></i>
                                &nbsp;{{ selectedDate.getFullYear() }}&nbsp;
                                <i class="fa fa-arrow-right" @click="next()" aria-hidden="true"></i>
                            </h2>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4" v-for="monthNumber in 12" :key="monthNumber">
                            <vue-cal :events="events" locale="es" class="vuecal--rounded-theme" active-view=" month"
                                :disable-views="['years', 'year', 'week', 'day']"
                                :selected-date="selectedDate.setMonth(monthNumber-1)" hide-view-selector
                                :transitions="false" xsmall @cell-click="nuevofestivo($event)">
                                <template #arrow-prev>
                                    <i></i>
                                </template>
                                <template #arrow-next>
                                    <i></i>
                                </template>
                                <template #events-count>
                                    <i>F</i>
                                </template>
                                <template #title="{ title}">{{ title.replace(/\d+/g, '') }}</template>
                            </vue-cal>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="card">
                <div class="card-header bg-gray">
                    <h3 class="card-title"><i class="fas fa-list mr-2"></i>Festivos</h3>
                </div>
                <div class="card-body">
                    <div v-for="evento in events" :key="evento">
                        <div class="row" >
                            <div class="col-md-3">
                                <b>{{evento.dia}}</b> del <b>{{evento.mes}}</b>
                            </div>
                            <div class="col-md-9">
                                <input type="text" @change="modificarevento(events.indexOf(evento))" style="width:100%" v-model.lazy="evento.nombre">
                            </div>
                        </div>
                        <p></p>
                    </div>
                    <div class="row">
                        <div class="col-md-4"></div>
                        <Button type="button" @click="guardarcambios()" label="Guardar"></Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'
import { PwgsApi } from '../../../services/PwgsApi';
export default ({
    props: [],
    data() {
        return {
            selectedDate: new Date(new Date().setMonth(0)), 
            events: [],
            mes: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            listafestivos: [],
            eventosaeliminar: [],
        }
    },
    setup() {
        return;
    },
    components: {
        VueCal
    },
    computed: {
    },
    methods: {
        previous() {
            this.selectedDate = this.selectedDate.subtractDays(365)
        },
        next() {
            this.selectedDate = this.selectedDate.addDays(365)
        },
        nuevofestivo(event) {
            let eventoform = { modificado:"nuevo",nombre: '', fecha: new Date(event).toLocaleDateString('es'),anyo: new Date(event).getFullYear(), start: event, end: event };
            let borrar = 0;
            let split = eventoform.fecha.split("/");
            eventoform.dia = split[0];
            eventoform.mes = split[1];
            for (let i = 0; i < this.events.length; i++){
                if (this.events[i].fecha == eventoform.fecha) {
                    if (this.events[i].id) {
                        this.eventosaeliminar.push(this.events[i].id);
                        this.events.splice(i, 1);
                    }
                    else{
                        this.events.splice(i, 1);
                    }
                    borrar = borrar + 1;
                }
            }
            if (borrar <= "0") {        
                this.events.push(eventoform);
            }
        },
        async crearfestivo(evento) { 
            //POST  modulos/pwgsapi/index.php/festivos
            let subidadatos = { nombre: evento.nombre, fecha: evento.anyo + "-" + evento.mes + '-' + evento.dia };
            const api = new PwgsApi();
            await api.post("festivos", subidadatos); 
        },
        modificarevento(indice) {
            if (this.events[indice].id > 0) { this.events[indice].modificado = "modificado"; }
            else { this.events[indice].modificado = "nuevo"; }
        },
       async modificarfestivo(evento) {
           //PUT  modulos/pwgsapi/index.php/festivos/:id
           const api = new PwgsApi();
           let subidadatos = {nombre:evento.nombre}
           await api.put("festivos/" + evento.id, subidadatos); 
        },
        async cargarfestivos() {
            this.events = [];
            this.eventosaeliminar = [];
            const api = new PwgsApi();
            let aux = await api.get('festivos/' + this.selectedDate.getFullYear());
            this.listafestivos = aux.datos;
            for (let i = 0; i < this.listafestivos.length; i++){
                let split = new Date(this.listafestivos[i].fecha).toLocaleDateString("es").split("/");
                let eventoform = { anyo:new Date(this.listafestivos[i].fecha).getFullYear(),anyodos: split[2],dia:split[0],mes:split[1],id: this.listafestivos[i].id, nombre: this.listafestivos[i].nombre, fecha: new Date(this.listafestivos[i].fecha).toLocaleDateString('es'),start: new Date(this.listafestivos[i].fecha), end: new Date(this.listafestivos[i].fecha)};
                this.events.push(eventoform);
            }
        },
        async eliminarevento(id) {
            const api = new PwgsApi();
            await api.delete('festivos/' + id); 
        },
        guardarcambios() {
            try{
            for (let i = 0; i < this.events.length; i++){
                if (this.events[i].modificado == "nuevo") {
                    this.crearfestivo(this.events[i]);
                }
                if (this.events[i].modificado == "modificado") {
                    this.modificarfestivo(this.events[i]);
                }
            }
            for (let j = 0; j < this.eventosaeliminar.length; j++){
                this.eliminarevento(this.eventosaeliminar[j]);
                }
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
            }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
            setTimeout(() => {
                this.cargarfestivos();
            }, 400); 
        }
    },
    mounted() {
        this.cargarfestivos();
    },
    watch: {
        selectedDate(){
            this.cargarfestivos();
        }
    }
})
</script>
<style>
.vuecal__cell-events-count{
    background: #f00;
    color: white;
}
</style>